import React, { useState, Fragment } from "react";
import classNames from "classnames";
import Button from "@hui/_atoms/button/Button";
import TextArea from "@hui/_atoms/textarea/Textarea";
import I18n from "../../../utilities/translations";

interface iEditableEmailText {
  editing: boolean;
  setEditing: (boolean) => void;
  clientName?: string;
  prevEmailText: string;
  setPrevEmailText: (string) => void;
  model?: "invoice" | "quote";
}

const ctx = { scope: "invoice_quotes.editable_email_text" }

const EditableEmailText = ({
  editing,
  setEditing,
  clientName,
  prevEmailText,
  setPrevEmailText,
  model = "invoice"
}: iEditableEmailText) => {
  const [emailText, setEmailText] = useState(`Hi${clientName ? ` ${clientName}` : ""},`);
  const [disableSave, setDisableSave] = useState(false);

  const handleSaveClick = () => {
    // TODO: fix me please
    // send request to save the new value
    // Shows loading and disabled button while saving

    setEditing(false);
    setPrevEmailText(emailText);
    window.analytics?.track(`${model}_custom_email_text_save_clicked`, "");
  }

  const handleEmailTextFocus = () => {
    setPrevEmailText(emailText);
  }

  const handleCancelClick = () => {
    setEditing(false);
    setEmailText(prevEmailText);
    window.analytics?.track(`${model}_custom_email_text_cancel_clicked`, "");
  }

  const handleEmailTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;

    setDisableSave(value.length > 700);
  }

  const handleCustomizeEmailClick = () => {
    setEditing(true);
    window.analytics?.track(`${model}_custom_email_text_edit_clicked`, "");
  }

  return (
    <div className="tw-flex tw-flex-col">
      <div className="tw-flex tw-justify-end">
        {editing ? (
          <div className="tw-flex tw-justify-between sm:tw-gap-1 tw-items-baseline">
            <Button
              variant="link"
              size="small"
              onClick={handleCancelClick}
              classes="!tw-capitalize"
            >
              {I18n.t("global.cancel")}
            </Button>
            <Button
              variant="link"
              onClick={handleSaveClick}
              iconType="CheckIcon"
              iconEnd
              size="small"
              classes="hnry-button--right-end"
              disabled={disableSave}
            >
              {I18n.t("save_changes", ctx)}
            </Button>
          </div>
        ) : (
          <div className="tw-flex">
            <Button
              variant="link"
              onClick={handleCustomizeEmailClick}
              iconType="PencilSquareIcon"
              size="small"
              classes="hnry-button--right-end"
              iconEnd
            >
              {I18n.t("customise_email_text", ctx)}
            </Button>
          </div>
        )}
      </div>
      {editing && (
        <>
          <TextArea
            id="customiseEmailTextarea"
            label="Email text"
            renderLabel={false}
            setValue={setEmailText}
            value={emailText}
            onFocus={handleEmailTextFocus}
            error={emailText.length > 700 && "Characters can't be more than 700"}
            onChange={handleEmailTextChange}
          />
          {emailText.length < 700 &&
            <div
              className={classNames("tw-flex tw-justify-end tw-text-xs tw-mt-2 tw-mb-4", {
                "tw-text-red": emailText.length >= 650,
                "tw-text-gray-500": emailText.length < 650,
              })}
            >
              {I18n.t("remaining_characters", { count: 700 - emailText.length, ...ctx})}
            </div>
          }
        </>
      )}
      {(!editing && emailText.length > 0) && (
        <p className="invoice-quote-email-text">
          {emailText.split(/\n/g).map((line, i) => <Fragment key={`customise-line-${i}`}><span>{line}</span><br /></Fragment>)}
        </p>
      )}
    </div>
  );
}

export default EditableEmailText;
