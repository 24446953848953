
import React, { useState } from "react";
import Button from "@hui/_atoms/button/Button";
import QRCode from "react-qr-code";
import I18n from "../../../utilities/translations";
import { iModalContentEl } from "../interface/WaitlistInterfaces";

const BrowserContent = ({ appPlatformName }: iModalContentEl) => {
    const [showQRCode, setShowQRCode] = useState(false);

    return (
        <div className="tw-text-gray-700">
            <p>{I18n.t("home.uk_waitlist.paragraph_1")}</p>
            <p className="tw-mb-0 tw-font-bold">
                {I18n.t("home.uk_waitlist.paragraph_2.title")}
            </p>
            <p>{I18n.t("home.uk_waitlist.paragraph_2.browser")}</p>

            {appPlatformName === "web_ios" &&
                (<a className="hnry-button hnry-button--primary !tw-block tw-w-full tw-mt-2" href="https://hnry.co.uk/app/ios" data-track-click='{ "eventName": "waitilst_download_mobile" }'>{I18n.t("home.uk_waitlist.buttons.get_app_ios")}</a>)
            }
            {appPlatformName === "web_android" &&
                (<a className="hnry-button hnry-button--primary !tw-block tw-w-full tw-mt-2" href="https://hnry.co.uk/app/android" data-track-click='{ "eventName": "waitilst_download_mobile" }'>{I18n.t("home.uk_waitlist.buttons.get_app_android")}</a>)
            }
            {appPlatformName !== "web_ios" && appPlatformName !== "web_android" &&
                <>
                    {showQRCode &&
                        <div className="tw-p-4 tw-border-gray-300 tw-border tw-rounded-lg">
                            <div className="tw-flex tw-justify-center"><QRCode value={"https://hnry.co.uk/?m=app&q=true"} className="tw-h-36 tw-w-36 tw-mb-4" /></div>
                            <p className="tw-text-center tw-font-semibold tw-mb-0">{I18n.t("home.uk_waitlist.qr_code")}</p>
                        </div>
                    }
                    {!showQRCode &&
                        <Button onClick={() => setShowQRCode(true)} variant="primary" classes="!tw-block tw-w-full tw-mt-2" dataTrackClick={{ eventName: "waitilst_download_mobile" }}>{I18n.t("home.uk_waitlist.buttons.get_app_other")}</Button>
                    }
                </>
            }
        </div>
    )
}


export default BrowserContent