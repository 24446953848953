import React, { useState, useEffect } from "react";
import Button from "@hui/_atoms/button/Button";

interface iInvoiceFooter {
  editing: boolean;
  sendLater: boolean;
  handleCancelClick: () => void;
}

const InvoiceFooter = ({
  editing,
  sendLater,
  handleCancelClick,
}: iInvoiceFooter) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (isLoading) {
      timeoutId = setTimeout(() => {
        setIsLoading(false);
      }, 5000);
    }

    return () => clearTimeout(timeoutId);
  }, [isLoading]);

  const onClick = (e) => {
    if (isLoading) {
      e.preventDefault();
      return;
    }
    setIsLoading(true);
  };

  return (
    <div className="hnry-dialog-panel-actions !tw-mt-4">
      {sendLater ? (
        <Button
          loading={isLoading}
          type="submit"
          name="schedule"
          value="schedule"
          onClick={onClick}
          dataTrackClick={{ eventName: "invoice_schedule_button_clicked" }}
          disabled={editing}
        >
          Schedule
        </Button>
      ) : (
        <Button
          loading={isLoading}
          type="submit"
          name="send"
          value="send"
          onClick={onClick}
          dataTrackClick={{ eventName: "invoice_send_button_clicked" }}
          disabled={editing}
        >
          Send
        </Button>
      )}
      <Button
        type="button"
        variant="secondary"
        aria-label="Close"
        onClick={handleCancelClick}
      >
        Cancel
      </Button>
    </div>
  );
};

export default InvoiceFooter;
