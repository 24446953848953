import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import FooterButtons from "./FooterButtons";
import ScheduleInputs from "./ScheduleInputs";
import { useInvoiceQuoteContext } from "../invoice_quote/InvoiceQuoteContext";
import InvoicePreviewModal from "./modals/InvoicePreviewModal";
import NotSendingWarning from "../invoice_quote/sections/NotSendingWarning";
import { removeButtonPopOver } from "../utils/base_helper";
import AdditionalInputs from "./AdditionalInputs"

const SettingsAndFooter = ({
  client,
  currencySymbol,
  depositsNotificationDismissed,
  invoiceClientSelected,
  invoiceStatus,
  isImpersonating = false,
  handleInvoiceEmailClick,
  customiseEmailTextEnabled,
}) => {

  const [disabledSave, setDisabledSave] = useState<boolean>(false);
  const [dateInvalidText, setDateInvalidText] = useState<string>("");
  const [timeInvalidText, setTimeInvalidText] = useState<string>("");
  const [untaxedDepositAccepted, setUntaxedDepositAccepted] = useState<boolean>(false);

  const {
    comments,
    dueDate,
    deposit,
    endDate,
    invoiceDate,
    invoiceTotalIncludingSalesTax,
    poNumber,
    scheduleDate,
    scheduleTime,
    setComments,
    setDueDate,
    setDeposit,
    setEndDate,
    setInvoiceDate,
    setPoNumber,
    setScheduleDate,
    setScheduleTime,
    setStartDate,
    startDate,
    otherProps,
    setOtherProps,
    grandTotal,
    lineItems
  } = useInvoiceQuoteContext();

  const [showDeposit, setShowDeposit] = useState(Boolean(deposit));

  const handlePhoneNumber = (value) => {
    setOtherProps({
      ...otherProps,
      hide_phone_number: value,
    });
  };

  // Ticking off all the different requirements for the Next button to be enabled/disabled

  const datesTimesValid = () => dateInvalidText.length === 0 && timeInvalidText.length === 0
  const lineItemsValid = () => {
    const nonEmptyLineItems = lineItems.filter(item => item.updated_name !== "" && item.total !== 0)
    return nonEmptyLineItems.length > 0
  }
  const grandTotalValid = () => grandTotal !== 0 || (grandTotal === 0 && lineItemsValid())
  const untaxedDepositValid = () => (showDeposit && Number(deposit) > 0 && untaxedDepositAccepted) || !showDeposit

  useEffect(() => {
    if (
      datesTimesValid() &&
      invoiceTotalIncludingSalesTax >= 0 &&
      grandTotalValid() &&
      untaxedDepositValid()
    ) {
      setDisabledSave(false);
    } else if (
      !datesTimesValid() ||
      invoiceTotalIncludingSalesTax < 0 ||
      !grandTotalValid() ||
      !untaxedDepositValid()
    ) {
      setDisabledSave(true);
    }
  }, [
    dateInvalidText,
    timeInvalidText,
    setDisabledSave,
    invoiceTotalIncludingSalesTax,
    grandTotal,
    lineItems,
    untaxedDepositAccepted,
    deposit,
    showDeposit
  ]);

  const openConfirmationModal = () => {
    $("#modal-invoice-email").modal("show");
    removeButtonPopOver();
    window.analytics?.track("invoice_email_modal_viewed", "");

    if (customiseEmailTextEnabled) {
      $("#modal-preview-invoice").modal("hide");
      handleInvoiceEmailClick();
    }
  };

  const openPreviewModal = () => {
    $("#modal-preview-invoice").modal("show");
  };

  const handleSendNowClick = (event) => {
    event.preventDefault();
    setScheduleDate("");
    setScheduleTime("");
    openConfirmationModal();
    setDisabledSave(true);
  };

  const handleDateInvalidText = (value) => {
    setDateInvalidText(value);
  };

  const handleTimeInvalidText = (value) => {
    setTimeInvalidText(value);
  };

  return (
    <>
      <NotSendingWarning
        client={client}
        invoiceClientSelected={invoiceClientSelected}
      />
      <ScheduleInputs
        dateInvalidText={dateInvalidText}
        dueDate={dueDate}
        endDate={endDate}
        invoiceDate={invoiceDate}
        scheduleDate={scheduleDate}
        scheduleTime={scheduleTime}
        startDate={startDate}
        setDateInvalidText={handleDateInvalidText}
        setDueDate={setDueDate}
        setEndDate={setEndDate}
        setInvoiceDate={setInvoiceDate}
        setScheduleDate={setScheduleDate}
        setScheduleTime={setScheduleTime}
        setStartDate={setStartDate}
        setTimeInvalidText={handleTimeInvalidText}
        timeInvalidText={timeInvalidText}
      />
      <AdditionalInputs
        comments={comments}
        setComments={setComments}
        poNumber={poNumber}
        setPoNumber={setPoNumber}
        hidePhoneNumber={otherProps?.hide_phone_number || false}
        setHidePhoneNumber={handlePhoneNumber}
        deposit={deposit}
        setDeposit={setDeposit}
        setUntaxedDepositAccepted={setUntaxedDepositAccepted}
        showDeposit={showDeposit}
        setShowDeposit={setShowDeposit}
        depositsNotificationDismissed={depositsNotificationDismissed}
      />
      <hr />
      {disabledSave && (timeInvalidText || dateInvalidText) && (
        <div className="alert alert-info" role="alert">
          Schedule date is in the past. If you no longer wish to schedule the
          invoice you can&nbsp;
          <button className="hui-link tw-pt-0" onClick={handleSendNowClick}>
            <strong>send now</strong>
          </button>
        </div>
      )}
      <FooterButtons
        invoiceStatus={invoiceStatus}
        isImpersonating={isImpersonating}
        disabledSave={disabledSave}
        handleNextClick={openConfirmationModal}
        handlePreviewClick={openPreviewModal}
        grandTotalValid={grandTotalValid()}
        untaxedDepositValid={untaxedDepositValid()}
        currencySymbol={currencySymbol}
        deposit={deposit}
        untaxedDepositAccepted={untaxedDepositAccepted}
      />
      <InvoicePreviewModal
        disabledSave={disabledSave}
        openConfirmationModal={openConfirmationModal}
        customiseEmailTextEnabled={customiseEmailTextEnabled}
      />
    </>
  );
};

SettingsAndFooter.propTypes = {
  advancedSettingsOpen: PropTypes.bool.isRequired,
  client: PropTypes.string.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  depositsNotificationDismissed: PropTypes.bool.isRequired,
  invoiceClientSelected: PropTypes.bool.isRequired,
  invoiceStatus: PropTypes.string.isRequired,
  isImpersonating: PropTypes.bool
};

export default SettingsAndFooter;
